import React from 'react';

import { ThemeProvider } from '@/themes/theme';
import { Helmet } from 'react-helmet';
import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import favicon from '@/images/favicon.ico';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import PageLayout from '@/components/ContentPageLayout/ContentPageLayout';

const NotFound = () => (
  <ThemeProvider>
    <Helmet>
      <title>Sky Breakers</title>
      <link rel="icon" href={favicon} />
      <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
    </Helmet>
    <GlobalStyle />
    <Wrapper>
      <Header />
      <PageLayout title="404" textalign="center">
        Sorry this page doesn’t exist
      </PageLayout>
      <Footer />
    </Wrapper>
  </ThemeProvider>
);

export default NotFound;
