import styled from 'styled-components';

interface ContentPageProps {
  textalign: string;
}

export const ContentPageLayoutHeader = styled.div`
  display: flex;
  justify-content: ${(props: ContentPageProps) =>
    props.textalign ? props.textalign : 'center'};
  margin-top: 200px;
  margin-bottom: 50px;
  @media (max-width: 767px) {
    margin-top: 170px;
  }
`;

export const ContentPageLayoutHeaderTitle = styled.h1`
  font-size: 80px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 88px;
  @media (max-width: 768px) {
    font-size: 50px;
    line-height: 60px;
  }
`;

export const ContentPageLayoutContentBox = styled.div`
  text-align: ${(props: ContentPageProps) =>
    props.textalign ? props.textalign : 'center'};
  margin-bottom: 150px;
  @media (max-width: 767px) {
    max-width: 350px;
  }
`;

export const ContentPageLayoutContent = styled.span`
  font-size: 20px;
  width: 100%;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 20.25px;
  }
`;
