import React, { FC } from 'react';
import {
  ContentPageLayoutHeader,
  ContentPageLayoutHeaderTitle,
  ContentPageLayoutContentBox,
  ContentPageLayoutContent,
} from '@/components/ContentPageLayout/ContentPageLayout.styled';
import Container from '@/components/Container';

interface IContentPageProps {
  title: string;
  children: any;
  textalign: any;
}

const ContentPageLayout: FC<IContentPageProps> = ({
  title,
  children,
  textalign,
}) => (
  <Container>
    <ContentPageLayoutHeader textalign={textalign}>
      <ContentPageLayoutHeaderTitle>{title}</ContentPageLayoutHeaderTitle>
    </ContentPageLayoutHeader>
    <ContentPageLayoutContentBox textalign={textalign}>
      <ContentPageLayoutContent>{children}</ContentPageLayoutContent>
    </ContentPageLayoutContentBox>
  </Container>
);

export default ContentPageLayout;
